html {
    /* Adjust font size */
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body,
html {
    font-family: @font-family;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 12.5px;
}


/* H1 - H6 font */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: @font-family;
    color: @bg-dark;
    .page-header & {
      margin-bottom: 0px;
    }
}

.content-wrapper {
    min-height: 881px !important;
    background-color: #f3f3f3;
}

.wrapper {
    background-color: @bg-dark;
}

.content {
    padding-top: 12px;
    padding-left: @content-gutter;
    padding-right: @content-gutter;
    padding-bottom: @content-gutter;
}

.loader,
div.dataTables_wrapper div.dataTables_processing {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99990;
    margin: 0;
    padding: 0;
    background: url('/img/loading.svg') 50% 50% no-repeat rgba(10, 10, 10, 0.5);
}


/**
 * A bootstrap column for five equal width columns in a row
 */

[class*="col-"].col-20 {
    width: 20%;
}

@media only screen and (max-width: 768px) {
    [class*="col-"].col-20 {
        width: 100%;
    }
}

.padding-15-bottom {
    padding-bottom: 15px;
}

.element-grafico {
    width: 100%;
    border: @light-border;
}

.element-head {
    text-align: center;
    color: #f5f5f5;
    padding: 10px 0px 10px 0px;
    background-color: @bg-dark;
    font-weight: bold;
    text-transform: uppercase;
}

.element-body {
    height: 300px;
    padding: 15px;
    background-color: white;
    position: relative;
}

.element-body-big-number {
  color: @base-color;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.label {
  font-size: .9rem;
}

h4 .progress {
  margin-bottom: 0;
}

.input-show {
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  white-space: nowrap;
}

.remove-border-top {
  border-top: 0 !important;
}

#doublescroll {
  overflow: auto; overflow-y: hidden;
}
#doublescroll div {
  padding: 1em;
  white-space: nowrap;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}

.list-group-item-list {
  padding-left: 16px;
  margin: 0;
}

@media screen and (max-width: 600px) {
  #insumos-table {width:100%;}
  #insumos-table thead {display: none;}
  #insumos-table tr:nth-of-type(2n) {background-color: inherit;}
  #insumos-table tr td:first-child {background: #f0f0f0; font-weight:bold;font-size:1.3em;}
  #insumos-table tbody td {display: block;  text-align:center;}
  #insumos-table tbody td:before {
    content: attr(data-th);
    display: block;
    text-align:center;
  }
}

.totalInsumos h5{
  font-weight: bold;
  color: #4a4a4a;
  font-size: 13px;
  margin: 0 10px;
  opacity: 0.5;
  text-transform: uppercase;
}
.totalInsumos h4{
  font-weight: bold;
  margin: 0 10px;
  color: #4a4a4a;
  font-size: 22px;
}
.totalInsumos{
  margin-bottom: 20px;
}

.color-provisionamento {
  color: #f1c100 !important;
}

.col-md-2.valores-totais {
  width: 14.28% !important;
}

.col-md-2.valores-totais-contrato {
  width: 15.7% !important;
}

.borda-direita {
  border-right: solid 1px #cecece;
}

hr {
  border-top: 1px solid #cecece !important;
}

.table > tbody > tr:hover > td {
	// background-color: rgba(158, 158, 158, 0.2) !important;
}

.icheckbox_minimal-grey.checked.disabled {
	background-color: #CCC;
}

.iradio_minimal-grey.disabled {
	border-radius: 50%;
	background-color: #CCC;
}
